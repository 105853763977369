import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { store } from "./app/store";
import * as serviceWorker from "./serviceWorker";
import routes from "./app/routes";
import Header from './app/components/header';
import "./app/styles/index.scss";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Header />
                <Switch>
                    {routes.map((route) => (
                        <Route
                            key={route.component.name}
                            component={route.component}
                            {...(route.options || {})}
                        />
                    ))}
                </Switch>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

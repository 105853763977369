import React from "react";

const Catchall = () => (
    <>
        <h1>501: Not implemented, yet.</h1>
    </>
);

const route = {
    component: Catchall,
    options: {
        path: undefined,
        exact: undefined,
    },
};

export default route;
